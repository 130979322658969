import "./App.css";
import "./assets/css/custom.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import AboutUs from "./screen/cms/AboutUs";
import Home from "./screen/Home";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Flip, ToastContainer } from "react-toastify";
import ProductList from "./screen/ProductList";
import Login from "./screen/auth/Login";
import LoginWithEmail from "./screen/auth/LoginWithEmail";
import Account from "./screen/auth/Account";
import ProductDetail from "./screen/ProductDetail";
import ProtectedRoute from "./screen/auth/ProtactRoute";
import Realstate from "./screen/Realstate";
import RealstateDetail from "./screen/RealstateDetail";
import SignupWithEmail from "./screen/auth/SignupWithEmail";
import UserUpdate from "./screen/auth/UserUpdate";
import JobList from "./screen/JobList";
import JobDetail from "./screen/JobDetail";
import FashionBeauty from "./screen/FashionBeauty";
import Freelancer from "./screen/Freelancer";
import FreelancerDetail from "./screen/FreelancerDetail";
import LessonCourses from "./screen/LessonCourses";
import LessonCoursesDetail from "./screen/LessonCoursesDetail";
import MyPost from "./screen/auth/account-action/MyPost";
import PostUpdate from "./screen/auth/account-action/PostUpdate";
import Chnagepassword from "./screen/auth/account-action/Chnagepassword";
import AddPost from "./screen/auth/account-action/AddPost";
import Wishlist from "./screen/auth/account-action/Wishlist";
import ContactUs from "./screen/cms/ContactUs";
import PrivacyPolicy from "./screen/cms/PrivacyPolicy";
import TermAndConditions from "./screen/cms/TermAndConditions";
import ElectronicsList from "./screen/ElectronicsList";
import ElectronicsDetail from "./screen/ElectronicsDetail";
import MainLoaidng from "./components/loading/MainLoaidng";
import { useAppContext } from "./contextApi/AppContext";
import FashionBeautyDetails from "./components/fashion-beauty/FashionBeautyDetails";
import Faq from "./screen/cms/Faq";
import ScrollToTop from "./components/ScrollToTop";
import VoiceCall from "./components/voiceCall/VoiceCall";
import ChatApp from "./components/videocall/ChatApp ";
import Disclaimer from "./screen/cms/Disclaimer";
import CustomerSupport from "./screen/cms/CustomerSupport";
import CartList from "./screen/auth/account-action/CartList";
import MyOrder from "./screen/auth/account-action/MyOrder";
import AllProductDetails from "./screen/AllProductDetails";
import Video from "./components/videocall/Video";
import { useLoadScript } from "@react-google-maps/api";
import InquiryPage from "./screen/auth/account-action/InquiryPage";
import { StripeResponse } from "./screen/payment/StripeResponse";
import MyEnrollments from "./screen/auth/account-action/MyEnrollments";
import SubscriptionDetail from "./screen/auth/account-action/SubscriptionDetail";
import SellerDetails from "./screen/SellerDetails/SellerDetails";
import WelcomeCourse from "./screen/auth/account-action/WelcomeCourse";
import ProductPostedSuccess from "./screen/ProductPostedSuccess";
import PaymentSuccess from "./screen/payment/PaymentSuccess";
import ForgotPassword from "./screen/auth/account-action/ForgotPassword";
import TeacherPage from "./screen/TeacherPage";
import { useEffect, useRef, useState } from "react";
import { connectSocket, listenToEvent } from "./socket_service";
import Packages from "./screen/auth/account-action/Packages";
import ApplicantList from "./screen/ApplicantList";
import WalletPage from "./screen/auth/account-action/payment/WalletPage";
import StatementPage from "./screen/auth/account-action/payment/StatementPage";
import MessageList from "./screen/MessageList";
import ConnectionList from "./screen/ConnectionListl";
import InvitationList from "./screen/InvitationList";
import SellerPanel from "./screen/seller_pannel/SellerPanel";
import { MyJobs } from "./screen/auth/account-action/MyJobs";
import { PostDetail } from "./screen/feed/post-detail";
import Feed from "./screen/feed/Feed";
import { PostReactions } from "./screen/feed/reaction-list";
import RefaundPolicy from "./screen/cms/RefaundPolicy";
import BlogMagzine from "./screen/cms/BlogMagzine";
import TapTop from "./TapTop";

const libraries = ["places"];

function App() {
  // eslint-disable-next-line
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAbznYqeVfwfeh0DQdKFF4tFAq276hU6cY",
    libraries,
  });
  const navigate = useNavigate();
  const [callData, setCallData] = useState({
    channel: null,
    token: null,
    room_type: "",
  });
  const { mainscreenLoading, setIncomingCall, userData, incomingCall } =
    useAppContext();
  const nodeRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    connectSocket();

    listenToEvent("incoming-call", (data) => {
      console.log("videocall data", data);
      console.log("videocall data", userData._id);
      if (data?.members && data?.members?.includes(userData?._id?.toString())) {
        setIncomingCall(true);
        setCallData({
          channel: data?.channel,
          token: data?.token,
          room_type: data?.room_type,
        });
      }
    });
    // eslint-disable-next-line
  }, [userData]);

  const handleAccept = () => {
    if (callData.token && callData.channel) {
      setIncomingCall(false);
      navigate(
        `/${callData.room_type === "audio" ? "voicecall" : "videocall"}/${
          callData.channel
        }/${callData.token}`
      );
    } else {
      navigate("/");
    }
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return <MainLoaidng />;

  return (
    <div className="App ">
      <div id="google_translate_element" style={{ textAlign: "right" }}>
        {" "}
      </div>
      {incomingCall && (
        <div className="incoming-call">
          <div className="icon-container">
            <i className="video-call-icon"></i>
          </div>
          <p>Incoming video call from {incomingCall}</p>
          <div className="button-group">
            <button onClick={handleAccept} className="accept-button">
              Accept
            </button>
            <button
              onClick={() => setIncomingCall(false)}
              className="reject-button"
            >
              Reject
            </button>
          </div>
        </div>
      )}

      {mainscreenLoading ? (
        <MainLoaidng />
      ) : (
        <>
          {location.pathname !== "/seller-pannel" && <Header />}
          <ScrollToTop />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            closeOnClick
            transition={Flip}
          />
          <div ref={nodeRef}>
            <Routes location={location}>
              <Route exact path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermAndConditions />}
              />
              <Route
                path="/refund-and-cancellation-policy"
                element={<RefaundPolicy />}
              />
              <Route
                path="/blog-and-magazine"
                element={<BlogMagzine />}
              />
              <Route path="/goods_of_all_kinds" element={<ProductList />} />
              <Route path="/electronics" element={<ElectronicsList />} />
              <Route
                path="/Electronics-detail/:id"
                element={<ElectronicsDetail />}
              />
              <Route path="/Login" element={<Login />} />

              <Route path="/Login-with-email" element={<LoginWithEmail />} />
              <Route path="/product-detail/:id" element={<ProductDetail />} />
              <Route path="/real_estate" element={<Realstate />} />
              <Route
                path="/realstate-detail/:id"
                element={<RealstateDetail />}
              />
              <Route path="/singup" element={<SignupWithEmail />} />
              <Route path="/jobs" element={<JobList />} />
              <Route
                path="/product/:slug/:id"
                element={<AllProductDetails />}
              />

              <Route path="/job-detail/:id" element={<JobDetail />} />
              <Route path="/fashion_beauty" element={<FashionBeauty />} />
              <Route
                path="/Fashion-Beauty-Details/:id"
                element={<FashionBeautyDetails />}
              />
              <Route path="/freelancer" element={<Freelancer />} />
              <Route
                path="/freelancer-detail/:id"
                element={<FreelancerDetail />}
              />
              <Route path="/lesson_courses" element={<LessonCourses />} />

              <Route path="/applicant-list" element={<ProtectedRoute />}>
                <Route index element={<ApplicantList />} />
              </Route>
              <Route
                path="/lessons-courses-detail/:id"
                element={<LessonCoursesDetail />}
              />

              {/* <Route path="/learn/welcome" element={<ProtectedRoute />}> */}
                <Route path="/learn/welcome" element={<WelcomeCourse />} />
              {/* </Route> */}

              <Route path="/packages" element={<ProtectedRoute />}>
                <Route index element={<Packages />} />
              </Route>

              <Route path="/my-jobs" element={<ProtectedRoute />}>
                <Route index element={<MyJobs />} />
              </Route>

              <Route path="/faq" element={<Faq />} />
              <Route path="/seller-info/:id" element={<SellerDetails />} />
              <Route
                path="/videocall/:channel/:token1"
                element={<Video type="video" />}
              />
              <Route
                path="/voicecall/:channel/:token1"
                element={<Video type="audio" />}
              />

              <Route path="/voice-call" element={<VoiceCall />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/customer_support" element={<CustomerSupport />} />
              <Route path="/stripe-response/:id" element={<StripeResponse />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />

              <Route
                path="*"
                element={
                  <div className="text-center py-5">
                    <img
                      src="https://img1a.flixcart.com/www/linchpin/fk-cp-zion/img/error-500_f9bbb4.png"
                      alt="Error 500"
                      className="img-fluid"
                      style={{ maxWidth: "450px" }}
                    />
                    <div className="mt-3 mb-4" style={{ fontSize: "1.3em" }}>
                      Unfortunately, the page you are looking for has been moved
                      or deleted
                    </div>
                    <Link to="/" className="btn btn-primary text-white">
                      Go To Home Page
                    </Link>
                  </div>
                }
              />

              <Route
                path="/new-password/:id/:token"
                element={<ForgotPassword />}
              />
              <Route path="/feed" element={<Feed />} />

              <Route path="/message" element={<ProtectedRoute />}>
                <Route index element={<ChatApp />} />
              </Route>

              <Route path="/account" element={<ProtectedRoute />}>
                <Route index element={<Account />} />
              </Route>

              <Route path="/my-post" element={<ProtectedRoute />}>
                <Route index element={<MyPost />} />
              </Route>

              <Route path="/my-post-inquiry/:id" element={<ProtectedRoute />}>
                <Route index element={<InquiryPage />} />
              </Route>

              <Route path="/seller-pannel" element={<ProtectedRoute />}>
                <Route index element={<SellerPanel />} />
              </Route>

              <Route path="/updateProduct/:id" element={<ProtectedRoute />}>
                <Route index element={<PostUpdate />} />
              </Route>

              <Route path="/add-post" element={<ProtectedRoute />}>
                <Route index element={<AddPost />} />
              </Route>

              <Route
                path="/product-posted-success"
                element={<ProtectedRoute />}
              >
                <Route index element={<ProductPostedSuccess />} />
              </Route>

              <Route path="/user-connectionlist" element={<ProtectedRoute />}>
                <Route index element={<ConnectionList />} />
              </Route>

              <Route path="/user-invitionlist" element={<ProtectedRoute />}>
                <Route index element={<InvitationList />} />
              </Route>

              <Route path="/wishlist" element={<ProtectedRoute />}>
                <Route index element={<Wishlist />} />
              </Route>
              <Route path="/message_list" element={<ProtectedRoute />}>
                <Route index element={<MessageList />} />
              </Route>

              <Route path="/my-subscription" element={<ProtectedRoute />}>
                <Route index element={<MyEnrollments />} />
              </Route>

              <Route
                path="/my-subscription/detail/:id"
                element={<ProtectedRoute />}
              >
                <Route index element={<SubscriptionDetail />} />
              </Route>

              <Route path="/cart" element={<ProtectedRoute />}>
                <Route index element={<CartList />} />
              </Route>

              <Route path="/my-Order" element={<ProtectedRoute />}>
                <Route index element={<MyOrder />} />
              </Route>
              <Route
                path="/my-Order/payment_request"
                element={<ProtectedRoute />}
              >
                <Route index element={<WalletPage />} />
              </Route>
              <Route
                path="/my-Order/check_statement"
                element={<ProtectedRoute />}
              >
                <Route index element={<StatementPage />} />
              </Route>

              <Route path="/my-Order" element={<ProtectedRoute />}>
                <Route index element={<MyOrder />} />
              </Route>

              <Route path="/course_instructor" element={<ProtectedRoute />}>
                <Route index element={<TeacherPage />} />
              </Route>

              <Route path="/change-password" element={<ProtectedRoute />}>
                <Route index element={<Chnagepassword />} />
              </Route>

              <Route path="/user-update" element={<ProtectedRoute />}>
                <Route index element={<UserUpdate />} />
              </Route>
              
                
              <Route path="/post-detail/:id" element={<ProtectedRoute />}>
                <Route index element={<PostDetail />} />
              </Route>
              <Route path="/post/reactions/:id" element={<ProtectedRoute />}>
                <Route index element={<PostReactions />} />
              </Route>
            </Routes>
            <TapTop />
          </div>
          {location.pathname !== '/seller-pannel' && (
            <Footer />
          )}
        </>
      )}
    </div>
  );
}

export default App;
