import React, { useState, useEffect } from "react";
import { AppContext } from "./AppContext";
import { toast } from "react-toastify";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../components/firebase/Firebase";
import { emitEvent } from "../socket_service";
import Cookies from "js-cookie";

const baseURL = "https://dzital.com";

const AppContextProvider = ({ children }) => {
  const [isLogIn, setIsLogIn] = useState(false);
  const [userData, setUserData] = useState({});
  const [peerUserData, setPeerUser] = useState({});
  const [cmsData, setCmsData] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [subServiceList, setSubServiceList] = useState([]);
  const [subServiceLoading, setSubServiceLoading] = useState(false);
  const [goodsList, setGoodsList] = useState([]);
  const [packagesList, setPackagesList] = useState([]);
  const [goodsItems, setGoodsItems] = useState({ isLoad: false, data: {} });
  const [globalCurrency, setGlobalCurrency] = useState("");
  const [currency_symbol, setCurrency_symbol] = useState("");
  const [electronicsItems, setElectronicsItems] = useState({
    data: {},
    isLoad: false,
  });
  const [electronicsList, setElectronicsList] = useState([]);
  const [tk, setTk] = useState("");
  const [loading, setLoading] = useState(false);
  const [mainscreenLoading, setMainscreenLoading] = useState(false);
  const [jobDetail, setJobDetail] = useState({ data: {}, isLoad: false });
  const [lessonDetail, setlessonDetail] = useState({ data: {}, isLoad: false });
  const [messageDetail, setMessageDetail] = useState(null);
  const [titleDes, setTitleDes] = useState({});
  const [reviews, setReviews] = useState([]);
  const [sitelocation, setSiteLocation] = useState("");
  const [incomingCall, setIncomingCall] = useState(null);
  const [getApplicantList, setGetApplicantList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [topCity, setTopCity] = useState([]);
  const [freelancerList, setFreelancerList] = useState([]);
  const [freelancerDetail, setFreelancerDetail] = useState({
    data: {},
    isLoad: false,
  });
  const [fashion, setFashion] = useState([]);
  const [fashionDetail, setFashionDetail] = useState({
    data: {},
    isLoad: false,
  });
  const [lesson, setLesson] = useState([]);
  const [property, setProperty] = useState([]);
  const [propertyDetail, setPropertyDetail] = useState({
    data: [],
    isLoad: false,
  });
  const [jobList, setJobList] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [totalprice, setTotalPrice] = useState();
  const [faq, setFaq] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  const [attributeList, setAttributeList] = useState([]);
  const [filterAttributeList, setFilterAttributeList] = useState([]);
  const [productId, setProductId] = useState([]);
  const [mypost, setMyPost] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [myPostDetail, setMyPostDetail] = useState({});
  const [allPDetails, setAllPDetails] = useState({});
  const [subsCourseDetail, setSubsCourseDetail] = useState([]);
  const [inquiry, setinquiry] = useState([]);
  const [check, setCheck] = useState(false);
  const [mainData, setMainData] = useState({});
  const [getConnectionsList, setGetConnectionsList] = useState([]);
  const [getreceivedList, setGetreceivedList] = useState([]);
  const [mysendreq, setmysendreq] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [totalItem, SetTotalItem] = useState({
    goods: 0,
    job: 0,
    freelancer: 0,
    RealEstate: 0,
    Lessons: 0,
    Fashion: 0,
    Electronics: 0,
    post: 0,
  });
  const [trendingPosts, setTrendingPosts] = useState({
    data: null,
    isLoading: false,
  });
  const [recentConnections, setRecentConnections] = useState({
    data: null,
    isLoading: false,
  });
  const [reactionList, setReactionList] = useState([]);
  const [reactionData, setReactionData] = useState({
    totalPage: 1,
    currentPage: 1,
    isLoading: false,
    totalReactions: 0,
  });

  const [listLoading, SetListLoading] = useState({
    goods: false,
    job: false,
    freelancer: false,
    RealEstate: false,
    Lessons: false,
    Fashion: false,
    Electronics: false,
    WishList: false,
    Post: false,
    cartList: false,
    orderList: false,
    connectionLists: false,
    invitationList: false,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const token = Cookies.get("token1");
  const [reletedList, SetReletedList] = useState([]);
  const [scheduledMeeting, setScheduledMeeting] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [sellerinfo, setSellerInfo] = useState([]);
  const [subsId, setSubsId] = useState();
  const [subsList, setSubsList] = useState([]);
  const [promoCode, setPromoCode] = useState({ loading: false, list: [] });
  const [jobAppliedList, setJobAppliedList] = useState({
    data: [],
    totalPages: 1,
  });
  const [sellerordersList, setSellerordersList] = useState([]);
  const [sellerTotalValue, setSellertotalValue] = useState({});
  const [courseOrderList, setCourseOrderList] = useState([]);
  const [courseLearner, setCourseLearner] = useState([]);

  const [sellerJobList, setSellerJobList] = useState({
    data: [],
    totalPages: 1,
    total: 0,
  });
  const [applicant_list, setApplicantList] = useState({
    data: [],
    totalPages: 1,
  });

  const [feedList, setFeedList] = useState([]);
  const [feedData, setFeedData] = useState({
    totalPage: 1,
    currentPage: 1,
    isLoading: false,
  });
  const [commentList, setCommentList] = useState([]);
  const [commentData, setCommentData] = useState({
    totalPage: 1,
    currentPage: 1,
    isLoading: false,
  });
  const [postDetail, setPostDetail] = useState({ data: {}, isLoading: false });
  const [premiumJobSeekers, setpremiumJobSeekers] = useState({
    data: {},
    isLoading: false,
  });
  const [topFreelancer, setTopFreelancer] = useState({
    data: {},
    isLoading: false,
  });
  const [topEducator, setTopEducator] = useState({
    data: {},
    isLoading: false,
  });

  const checkTokenValidity = async () => {
    const token = Cookies.get("token1");
    setLoading(true);
    if (tk || token) {
      try {
        const response = await fetch(`${baseURL}/api/profile/details`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: tk || token,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setUserData(data.data);
          setIsLogIn(true);
        } else {
          setIsLogIn(false);
        }
      } catch (error) {
        setIsLogIn(false);
      }
    } else {
      setIsLogIn(false);
    }
    setLoading(false);
  };

  const getPeerUserDetail = async (id) => {
    const token = Cookies.get("token1");
    if (tk || token) {
      try {
        const response = await fetch(
          `${baseURL}/api/profile/details?user_id=${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: tk || token,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setPeerUser(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleLogin = async (token, message) => {
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get("redirect");
    setTk(token);
    Cookies.set("token1", token);
    await checkTokenValidity();
    toast.success(message);
    if (paramValue) {
      navigate(`${paramValue}`);
    } else {
      navigate("/");
    }
  };

  const RegisterUser = async (name, email, password) => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);
      const response = await axios.post(`${baseURL}/api/register`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const data = response.data;
      if (response.status === 200) {
        handleLogin(data.token, data.message);
        setIsLogIn(true);
      } else {
        setIsLogIn(false);
        toast.error(data.message);
      }
    } catch (error) {
      setIsLogIn(false);
    }
  };

  const LoginUser = async (email, password) => {
    try {
      setLoading(true);
      const req = await fetch(`${baseURL}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
      const data = await req.json();
      if (req.ok) {
        handleLogin(data.token, data.message);
        setIsLogIn(true);
      } else {
        setIsLogIn(false);
        toast.error(data.message);
      }
    } catch (error) {
      setIsLogIn(false);
      // toast.error("Server Down");
    } finally {
      setLoading(false);
    }
  };

  const ChnagePassword = async (currentpass, newpassword) => {
    try {
      const req = await fetch(`${baseURL}/api/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({
          currentPassword: currentpass,
          newPassword: newpassword,
        }),
      });
      const data = await req.json();
      console.log("data", data);
      if (req.ok) {
        toast.success(data.message);
        // LogOut();
        navigate("/account");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Server Down");
    }
  };

  const SocialLoginUser = async (email, socialId, provider, name, photo) => {
    try {
      setLoading(true);
      const req = await fetch(`${baseURL}/api/social-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          social_id: socialId,
          register_type: provider,
          image: photo,
          name: name,
        }),
      });
      const data = await req.json();
      if (req.ok) {
        handleLogin(data.token, data.message);
        setIsLogIn(true);
      } else {
        setIsLogIn(false);
        toast.error(data.message);
      }
    } catch (error) {
      // toast.error("Server Down");
    } finally {
      setLoading(false);
    }
  };

  const LogOut = async () => {
    try {
      const token = Cookies.get("token1");
      const response = await fetch(`${baseURL}/api/logout`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.removeItem("token1");
        Cookies.remove("token1");

        setUserData({});
        setIsLogIn(false);
        signOut(auth);
        toast.success(data.message);
        setTk("");
      }
    } catch (error) {
      toast.error("Server Down");
    }
  };

  const getUser = async () => {
    await checkTokenValidity();
  };

  const updateBank = async (
    bank_name,
    account_number,
    ifsc_code,
    beneficiary_name
  ) => {
    const form = new FormData();
    form.append("bank_name", bank_name);
    form.append("account_number", account_number);
    form.append("ifsc_code", ifsc_code);
    form.append("beneficiary_name", beneficiary_name);
    try {
      //eslint-disable-next-line
      const req = await axios.post(`${baseURL}/api/profile/update`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: token,
        },
      });
      toast.success("Bank Details updated successfully");
      getUser();
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const updateNewProfile = async (dataToSend) => {
    try {
      console.log(dataToSend, "data");
      const req = await axios.post(
        `${baseURL}/api/profile/update`,
        dataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        }
      );
      const res = req.data;
      toast(res.message);
      getUser(); // Refresh user data if needed
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const getCms = async () => {
    try {
      const response = await fetch(`${baseURL}/api/cms/getAll`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (response.ok) {
        setCmsData(data?.data);
      }
    } catch (error) {
      toast.error("Server Down");
    }
  };

  const getServiceList = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/api/service/list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        setServiceList(data.data);
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    }
    setLoading(false);
  };

  const getSubServiceList = async (parentId) => {
    try {
      setSubServiceLoading(true);
      const response = await fetch(`${baseURL}/api/service/sub-category/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          parentId,
        }),
      });
      const data = await response.json();

      if (response.ok) {
        setSubServiceList(data.data);
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    } finally {
      setSubServiceLoading(false);
    }
  };

  const AddTitleDes = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/user/product/add`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );

      const data = response.data;
      if (response.status === 200) {
        toast.success(data.message);
        setProductId(data.data._id);
        setTitleDes({
          title: data.data.title,
          description: data.data.description,
        });
      }
      return data;
    } catch (error) {
      toast.error(error.message || "Server Down");
      console.error(error);
    }
  };

  const AddRealEstate = async (formdata) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseURL}/api/property/add`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        }
      );
      const data = response;
      toast.success(data.message || "Success");
      const serializedData = encodeURIComponent(JSON.stringify(data.data));
      navigate(`/product-posted-success?data=${serializedData}`);
      return data;
    } catch (error) {
      // toast.error("Server Down");
      toast.error(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const AddGoodsProduct = async (formdata) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseURL}/api/product/goods/add`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        }
      );
      const data = response;
      toast.success(data.message || "Success");
      console.log(data.data);
      const serializedData = encodeURIComponent(JSON.stringify(data.data));
      navigate(`/product-posted-success?data=${serializedData}`);
      return data;
    } catch (error) {
      toast.error(error);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const AddCourse = async (formdata) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseURL}/api/product/lesson-course/add`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        }
      );
      const data = response.data;
      toast.success(data.message || "Success");
      const serializedData = encodeURIComponent(JSON.stringify(data.data));
      navigate(`/product-posted-success?data=${serializedData}`);
      setCheck(false);
      return data;
    } catch (error) {
      setCheck(false);
      toast.error(error.message || "Error occurred");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const AddElectronics = async (formdata) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseURL}/api/product/electronics/add`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        }
      );
      const data = response;
      toast.success(data.message || "Success");
      const serializedData = encodeURIComponent(JSON.stringify(data));
      navigate(`/product-posted-success?data=${serializedData}`);
      return data;
    } catch (error) {
      toast.error(error);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const AddFreelancer = async (formdata) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseURL}/api/product/freelancer/add`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        }
      );
      const { data } = response;
      toast.success(data.message || "Success");
      const serializedData = encodeURIComponent(JSON.stringify(data.data));
      navigate(`/product-posted-success?data=${serializedData}`);
      return data.data;
    } catch (error) {
      toast.error(error);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const AddJob = async (formdata) => {
    try {
      setLoading(true);
      const response = await axios.post(`${baseURL}/api/job/post`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: token,
        },
      });
      const data = response.data; // Access the data property
      toast.success(data.message || "Success");
      const serializedData = encodeURIComponent(JSON.stringify(data.data));
      navigate(`/product-posted-success?data=${serializedData}`);
      return data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || "An error occurred";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const AddFashionProduct = async (formdata) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseURL}/api/product/fashion/add`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        }
      );
      const data = response;
      toast.success(data.message || "Success");
      const serializedData = encodeURIComponent(JSON.stringify(data.data));
      navigate(`/product-posted-success?data=${serializedData}`);
      return data;
    } catch (error) {
      toast.error(error);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getGoodsProduct = async (searchData, id, filter, dataToSend) => {
    try {
      SetListLoading({ ...listLoading, goods: true });
      const { keyword_search, page, limit, address, minPrice, maxPrice } =
        searchData;
      const { addressName, currency } = dataToSend;
      const response = await fetch(`${baseURL}/api/product/goods/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keyword_search: keyword_search || "",
          page: page,
          limit: limit,
          sub_category_id: id || "",
          filter: Array.isArray(filter) && filter.length > 0 ? filter : null,
          ...(isLogIn && { userId: userData._id }),
          address: address || addressName,
          currency: currency,
          minPrice: minPrice,
          maxPrice: maxPrice,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setSkeleton(true);
        setGoodsList(data.data);
        SetTotalItem({ ...totalItem, goods: data.total });
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    } finally {
      setSkeleton(false);
      SetListLoading({ ...listLoading, goods: false });
    }
  };

  const getGoodsProductDetails = async (dataToSend) => {
    try {
      setGoodsItems({ data: {}, isLoad: true });
      const { id, currency } = dataToSend;
      const response = await axios.post(
        `${baseURL}/api/product/goods/details`,
        {
          id: id, // Pass id in the request body
          userId: userData._id,
          currency: currency,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set content type header
          },
        }
      );

      if (response.status === 200) {
        setGoodsItems({ data: response.data.data, isLoad: false });
      } else {
        setGoodsItems({ data: {}, isLoad: false });
      }
    } catch (error) {
      setGoodsItems({ data: {}, isLoad: false });
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getElectronicsProduct = async (searchData, id, filter, dataToSend) => {
    try {
      SetListLoading({ ...listLoading, Electronics: true });
      const { keyword_search, page, limit, address, minPrice, maxPrice } =
        searchData;
      const { addressName, currency } = dataToSend;
      const response = await fetch(`${baseURL}/api/product/electronics/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keyword_search: keyword_search || "",
          page: page,
          limit: limit,
          sub_category_id: id || "",
          filter: Array.isArray(filter) && filter.length > 0 ? filter : null,
          ...(isLogIn && { userId: userData._id }),
          address: address || addressName,
          currency: currency,
          minPrice: minPrice,
          maxPrice: maxPrice,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setElectronicsList(data.data);
        SetTotalItem({ ...totalItem, Electronics: data.total });
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    } finally {
      SetListLoading({ ...listLoading, Electronics: false });
    }
  };

  const getElectronicsProductDetails = async (dataToSend) => {
    try {
      setElectronicsItems({ data: {}, isLoad: true });
      const { id, currency } = dataToSend;
      const response = await axios.post(
        `${baseURL}/api/product/electronics/details`,
        {
          id: id,
          userId: userData?._id || null,
          currency: currency,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set content type header
          },
        }
      );

      if (response.status === 200) {
        setElectronicsItems({ data: response.data.data, isLoad: false });
      } else {
        setElectronicsItems({ data: {}, isLoad: false });
      }
    } catch (error) {
      setElectronicsItems({ data: {}, isLoad: false });
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getFreelancer = async (searchData, id, filter, dataToSend) => {
    try {
      SetListLoading({ ...listLoading, freelancer: true });
      const { addressName, currency } = dataToSend;
      const { keyword_search, page, limit, address } = searchData;
      const response = await fetch(`${baseURL}/api/product/freelancer/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keyword_search: keyword_search || "",
          page: page,
          limit: limit,
          sub_category_id: id || "",
          filter: Array.isArray(filter) && filter.length > 0 ? filter : null,
          ...(isLogIn && { userId: userData._id }),
          address: address || addressName,
          currency: currency,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setFreelancerList(data.data);
        SetTotalItem({ ...totalItem, freelancer: data.total });
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    } finally {
      SetListLoading({ ...listLoading, freelancer: false });
    }
  };

  const getFreelancerDetails = async (dataToSend) => {
    try {
      setFreelancerDetail({ data: {}, isLoad: true });
      const { id, currency } = dataToSend; // Pass currency in the request body
      const response = await axios.post(
        `${baseURL}/api/product/freelancer/details`,
        {
          id: id, // Pass id in the request body
          userId: userData._id,
          currency: currency,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set content type header
          },
        }
      );

      if (response.status === 200) {
        setFreelancerDetail({ data: response.data.data, isLoad: false });
      } else {
        setFreelancerDetail({ data: {}, isLoad: false });
      }
    } catch (error) {
      setFreelancerDetail({ data: {}, isLoad: false });
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getFashionBeauty = async (
    searchData,
    id,
    filter,
    dataToSend,
    min_price,
    max_price
  ) => {
    try {
      SetListLoading({ ...listLoading, Fashion: true });
      const { keyword_search, page, limit, address } = searchData;
      const { addressName, currency } = dataToSend;
      const response = await fetch(`${baseURL}/api/product/fashion/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keyword_search: keyword_search || "",
          page: page,
          limit: limit,
          sub_category_id: id,
          filter: Array.isArray(filter) && filter.length > 0 ? filter : null,
          ...(isLogIn && { userId: userData._id }),
          address: address || addressName,
          min_price,
          max_price,
          currency: currency,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setFashion(data.data);
        SetTotalItem({ ...totalItem, Fashion: data.total });
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    } finally {
      SetListLoading({ ...listLoading, Fashion: false });
    }
  };

  const getFashionBeautyDetails = async (dataToSend) => {
    try {
      setFashionDetail({ data: {}, isLoad: true });
      const { id, currency } = dataToSend;
      const response = await axios.post(
        `${baseURL}/api/product/fashion/details`,
        {
          id: id,
          userId: userData?._id || null,
          currency: currency,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFashionDetail({ data: response.data.data, isLoad: false });
      } else {
        setFashionDetail({ data: {}, isLoad: false });
      }
    } catch (error) {
      setFashionDetail({ data: {}, isLoad: false });
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getProperty = async (searchData, id, filter, dataToSend) => {
    try {
      SetListLoading({ ...listLoading, RealEstate: true });
      const { keyword_search, page, limit, property_type, address } =
        searchData;
      const { addressName, currency } = dataToSend;
      const response = await fetch(`${baseURL}/api/property/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keyword_search: keyword_search || "",
          page: page,
          limit: limit,
          sub_category_id: id,
          property_type: property_type,
          filter: Array.isArray(filter) && filter.length > 0 ? filter : null,
          ...(isLogIn && { userId: userData._id }),
          address: address || addressName,
          currency,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setProperty(data.data);
        SetTotalItem({ ...totalItem, RealEstate: data.total });
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log("error: ", error);
    } finally {
      SetListLoading({ ...listLoading, RealEstate: false });
    }
  };

  const getPropertyDetail = async (dataToSend) => {
    try {
      setPropertyDetail({ data: [], isLoad: true });
      const { id, currency } = dataToSend;
      const response = await axios.post(
        `${baseURL}/api/property/details`,
        {
          id: id, // Pass id in the request body
          userId: userData._id,
          currency: currency,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set content type header
          },
        }
      );

      if (response.status === 200) {
        setPropertyDetail({ data: response.data.data, isLoad: false });
      } else {
        setPropertyDetail({ data: [], isLoad: false });
      }
    } catch (error) {
      setPropertyDetail({ data: [], isLoad: false });
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getLesson = async (searchData, id, filter) => {
    try {
      SetListLoading({ ...listLoading, Lessons: true });
      const { keyword_search, page, limit } = searchData;
      const response = await fetch(
        `${baseURL}/api/product/lesson-courses/list`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            keyword_search: keyword_search || "",
            page: page,
            limit: limit,
            sub_category_id: id,
            filter: Array.isArray(filter) && filter.length > 0 ? filter : null,
            ...(isLogIn && { userId: userData._id }),
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setLesson(data.data);
        SetTotalItem({ ...totalItem, Lessons: data.total });
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    } finally {
      SetListLoading({ ...listLoading, Lessons: false });
    }
  };

  const getLessonDetail = async (dataToSend) => {
    const { id, currency } = dataToSend;
    try {
      setlessonDetail({ data: {}, isLoad: true });

      const response = await axios.post(
        `${baseURL}/api/product/lesson-course/detail`,
        {
          id: id,
          userId: userData?._id || null,
          currency: currency,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setlessonDetail({ data: response.data.data, isLoad: false });
      } else {
        setlessonDetail({ data: {}, isLoad: false });
      }
    } catch (error) {
      setlessonDetail({ data: {}, isLoad: false });
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getJobList = async (searchData, id, filter, dataToSend) => {
    try {
      SetListLoading({ ...listLoading, job: true });
      const { keyword_search, page, limit, address } = searchData;
      const { addressName } = dataToSend;
      const response = await fetch(`${baseURL}/api/product/job/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keyword_search: keyword_search || "",
          page: page,
          limit: limit,
          sub_category_id: id || "",
          filter: Array.isArray(filter) && filter.length > 0 ? filter : null,
          ...(isLogIn && { userId: userData._id }),
          address: address || addressName,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setJobList(data.data);
        SetTotalItem({ ...totalItem, job: data.total });
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    } finally {
      SetListLoading({ ...listLoading, job: false });
    }
  };

  const getJobListDetail = async (id) => {
    try {
      setJobDetail({ data: {}, isLoad: true });
      const url = userData?._id
        ? `${baseURL}/api/job/details/${id}?userId=${userData._id}`
        : `${baseURL}/api/job/details/${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      // console.log('jobdetail', data)
      if (response.ok) {
        setJobDetail({ data: data.data, isLoad: false });
      } else {
        setJobDetail({ data: {}, isLoad: false });
      }
    } catch (error) {
      setJobDetail({ data: {}, isLoad: false });
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getMyPost = async (searchData) => {
    try {
      SetListLoading({ ...listLoading, Post: true });
      const { keyword_search, page, limit } = searchData;
      const response = await fetch(`${baseURL}/api/user/product/list`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({
          keyword_search: keyword_search || "",
          page: page,
          limit: limit,
        }),
      });
      const data = await response.json();
      // console.log('setMyPost', data)
      if (response.ok) {
        setMyPost(data.data);
        SetTotalItem({ ...totalItem, post: data.total });
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    } finally {
      SetListLoading({ ...listLoading, Post: false });
    }
  };

  const myPostRemove = async (id) => {
    try {
      const response = await fetch(`${baseURL}/api/user/product/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
        getMyPost(id);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getMyPostListDetail = async (id) => {
    try {
      const response = await fetch(`${baseURL}/api/user/product/detail/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setMyPostDetail(data.data);
      } else {
        console.log("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateMyPost = async (id, formdata, isRestrict) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/user/product/update/${id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        }
      );
      const data = response;
      if (!isRestrict) {
        toast.success(data.message || "Success");
        navigate("/my-post");
      }
      return data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getWishList = async () => {
    try {
      SetListLoading({ ...listLoading, WishList: true });
      const response = await fetch(`${baseURL}/api/user/product/wishlist`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();
      // console.log('wishlist', data.data.wishlist)
      if (response.ok) {
        setWishList(data.data.wishlist);
      }
    } catch (error) {
      toast.error("Server Down");
      // console.log(error);
    } finally {
      SetListLoading({ ...listLoading, WishList: false });
    }
  };

  const wishListAdd = async (id) => {
    try {
      if (!isLogIn) {
        toast.info("Login first");
        navigate("/login");
        return;
      } else {
        const response = await fetch(
          `${baseURL}/api/user/product/wishlist/add`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
            body: JSON.stringify({
              product_id: id,
            }),
          }
        );
        const data = await response.json();
        // const wishListData = data.data[0]
        if (response.ok) {
          toast.success(data.message);
          getWishList();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const wishListRemove = async (id) => {
    try {
      if (!isLogIn) {
        toast.info("Login first");
        navigate("/login");
        return;
      } else {
        const response = await fetch(
          `${baseURL}/api/user/product/wishlist/remove`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
            body: JSON.stringify({
              product_id: id,
            }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          toast.warning(data.message);
          getWishList();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getCartList = async () => {
    try {
      SetListLoading({ ...listLoading, cartList: true });
      const response = await fetch(`${baseURL}/api/user/product/cart`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setCartList(data.data?.items || []);
        setTotalPrice(data.data?.total_cart_price || 0);
        if (!data?.data?.items || data?.data?.items?.length === 0) {
          toast.info(data?.message);
        }
      }
    } catch (error) {
      toast.error("Server Down");
      // console.log(error);
    } finally {
      SetListLoading({ ...listLoading, cartList: false });
    }
  };

  const getMyOrderList = async () => {
    try {
      SetListLoading({ ...listLoading, orderList: true });
      const response = await fetch(`${baseURL}/api/order-list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setOrderList(data.data);
      }
    } catch (error) {
      toast.error("Server Down");
      // console.log(error);
    } finally {
      SetListLoading({ ...listLoading, orderList: false });
    }
  };

  const cartListAdd = async (id, quantity, price) => {
    try {
      if (!isLogIn) {
        toast.info("Login first");
        navigate("/login");
        return;
      } else {
        const response = await fetch(`${baseURL}/api/user/product/cart/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify({
            product_id: id,
            quantity: quantity,
            price: price,
          }),
        });
        const data = await response.json();
        // const wishListData = data.data[0]
        if (response.ok) {
          toast.success(data.message);
          getCartList();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const cartListRemove = async (id) => {
    try {
      if (!isLogIn) {
        toast.info("Login first");
        navigate("/login");
        return;
      } else {
        const response = await fetch(
          `${baseURL}/api/user/product/cart/remove`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
            body: JSON.stringify({
              product_id: id,
            }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          toast.success(data.message);
          getCartList();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const cartListClear = async () => {
    try {
      if (!isLogIn) {
        toast.info("Login first");
        navigate("/login");
        return;
      } else {
        const response = await fetch(`${baseURL}/api/user/product/cart/clear`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        });
        if (response.ok) {
          getCartList();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const cartListUpdate = async (id, quantity) => {
    try {
      if (!isLogIn) {
        toast.info("Login first");
        navigate("/login");
        return;
      } else {
        const response = await fetch(
          `${baseURL}/api/user/product/cart/quantity`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
            body: JSON.stringify({
              product_id: id,
              quantity,
            }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          toast.success(data.message);
          getCartList();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const orderCheckOut = async (body) => {
    try {
      if (!isLogIn) {
        toast.info("Login first");
        navigate("/login");
        return;
      } else {
        const response = await fetch(`${baseURL}/api/order-add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify(body),
        });
        const data = await response.json();
        if (response.ok) {
          toast.success(data.message);
          cartListClear();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getFaq = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${baseURL}/api/all-faq`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setFaq(data.data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getUserplan = async () => {
    try {
      const response = await fetch(`${baseURL}/api/user/membership/plan/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setPackagesList(data.data);
      }
    } catch (error) {
      toast.error("Server Down");
    }
  };

  const getAttributeList = async (attributeData) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/attribute/list`,
        {
          category_id: attributeData.category_id || "",
          sub_category_id: attributeData.sub_category_id || "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response;
      setAttributeList(data.data.data);
      return data;
    } catch (error) {
      // console.log(error);
    }
  };

  const getAttributeFilterList = async (obj) => {
    try {
      const body = obj.main
        ? {
            category_id: obj.main,
          }
        : obj.sub
        ? {
            sub_category_id: obj.sub,
          }
        : {};
      const { data } = await axios.post(
        `${baseURL}/api/attribute/filter/list`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setFilterAttributeList(data.data);
      return data;
    } catch (error) {
      // console.log(error);
    }
  };

  const getReletedProduct = async (
    category_id,
    sub_category_id,
    product_id
  ) => {
    try {
      const response = await fetch(`${baseURL}/api/product/related/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          category_id,
          product_id,
          // sub_category_id: sub_category_id || '',
        }),
      });
      const data = await response.json();
      if (response.ok) {
        SetReletedList(data.data);
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    } finally {
      SetListLoading({ ...listLoading, Fashion: false });
    }
  };

  const getBannerList = async () => {
    try {
      const response = await fetch(`${baseURL}/api/banner/banner-list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setBannerList(data.data);
      }
    } catch (error) {
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getAllProductDetails = async (dataToSend) => {
    try {
      setAllPDetails({ data: {}, isLoad: true });
      const { id, currency } = dataToSend;
      const response = await axios.post(
        `${baseURL}/api/wishlist/product/details`,
        {
          product_id: id,
          userId: userData._id,
          currency: currency,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: token, // Include token in the headers
          },
        }
      );

      if (response.status === 200 && response.data.status === 200) {
        setAllPDetails({ data: response.data.data, isLoad: false });
      } else {
        setAllPDetails({ data: {}, isLoad: false });
      }
    } catch (error) {
      setAllPDetails({ data: {}, isLoad: false });
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getAllUnAuthProductDetails = async (dataToSend) => {
    try {
      setAllPDetails({ data: {}, isLoad: true });
      const { id, currency } = dataToSend;
      const response = await axios.post(
        `${baseURL}/api/common/product/detail`,
        {
          product_id: id,
          userId: userData._id,
          currency: currency,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set content type
          },
        }
      );

      if (response.status === 200 && response.data.status === 200) {
        setAllPDetails({ data: response.data.data, isLoad: false });
      } else {
        setAllPDetails({ data: {}, isLoad: false });
      }
    } catch (error) {
      setAllPDetails({ data: {}, isLoad: false });
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getMessageDetail = async () => {
    try {
      const response = await fetch(`${baseURL}/api/chat/user/token/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();
      if (response.ok && data.status === 200) {
        setMessageDetail(data);
      }
    } catch (error) {
      toast.error("Server Down");
    }
  };

  const getCallDetail = async (members, room_type) => {
    try {
      const response = await fetch(`${baseURL}/api/room/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({
          members,
          room_type,
        }),
      });
      const data = await response.json();
      if (response.ok && data.status === 200) {
        const tk = encodeURIComponent(data?.data?.token);
        navigate(
          `/${room_type === "audio" ? "voicecall" : "videocall"}/${
            data?.data?.channelName
          }/${tk}`
        );
        emitEvent("incoming_call", {
          members,
          room_type,
          channel: data?.data?.channelName,
          token: tk,
        });
      }
    } catch (error) {
      toast.error("Server Down");
    }
  };

  const jobApply = async (formData) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/job/application`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
      } else if (response.status === 400) {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while submitting the application."
      );
    }
  };

  const BidAdd = async (amount, id) => {
    try {
      setSubServiceLoading(true);
      const response = await fetch(`${baseURL}/api/product/bid/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({
          bid_amount: amount,
          productId: id,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const sellerinquiry = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/contact-to-supplier`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
      } else if (response.status === 400) {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while submitting the application."
      );
    }
  };

  const getScheduledMeetingsList = async (meeting) => {
    try {
      const response = await fetch(`${baseURL}/api/zoom/meeting/create`, {
        method: "POST",
        headers: {
          token: token,
          "content-type": "application/json",
        },
        body: JSON.stringify(meeting),
      });
      const data = await response.json();
      if (response.ok) {
        setScheduledMeeting(data.data);
        toast.success(data.message);
      } else {
        console.error("Error:", data);
        // toast.error("Error scheduling meeting");
      }
    } catch (error) {
      console.error("Network or server error:", error);
      // toast.error("Server Down");
    }
  };

  const getPlanCreate = async (formData, id) => {
    try {
      const response = await fetch(`${baseURL}/api/product/plan/create`, {
        method: "POST",
        headers: {
          token: token,
          "content-type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
        const dataPost = new FormData();
        dataPost.append("product_price", "0");
        dataPost.append("purchase_mode", "Subscription");
        await updateMyPost(id, dataPost, true);
        setCheck(true);
        window.location.reload();
      } else {
        console.error("Error:", data);
        toast.error(data?.message || "Server Down");
        // toast.error("Error scheduling meeting");
      }
    } catch (error) {
      console.error("Network or server error:", error);
      toast.error(error?.response?.data?.message || "Server Down");
    }
  };

  const getinquiryList = async (dataToSend) => {
    try {
      SetListLoading({ ...listLoading, cartList: true });
      const response = await axios.post(
        `${baseURL}/api/seller/contact-to-supplier/list`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );

      if (response.status === 200) {
        setinquiry(response.data.data);
      }
    } catch (error) {
      toast.error("Server Down");
      // console.log(error);
    } finally {
      SetListLoading({ ...listLoading, cartList: false });
    }
  };

  const createFreePayment = async (product_id) => {
    try {
      if (!token) {
        toast.info("Login first");
        return;
      }
      const { data } = await axios.post(
        `${baseURL}/api/user/free/subscription/create`,
        { product_id },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (data.status === 200) {
        toast.success(data?.message);
        setSubsId(data?.data?._id);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "server error");
    } finally {
      console.log("first");
    }
  };

  const getSubscriptionList = async () => {
    try {
      setAllPDetails({ data: {}, isLoad: true });
      const response = await fetch(`${baseURL}/api/user/subscription/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = await response.json();
      if (response.ok && data.status === 200) {
        setSubscriptionList(data.data);
      } else {
        toast.error(data?.message || "Server Down");
      }
    } catch (error) {
      setAllPDetails({ data: {}, isLoad: false });
      // toast.error("Server Down");
      console.log(error);
    }
  };

  const getSubsCourseDetail = async (id) => {
    try {
      const response = await fetch(
        `${baseURL}/api/user/subsription/detail/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = await response.json();

      if (response.ok && data.status === 200) {
        setSubsCourseDetail(data?.data);
      } else {
        toast.error(data?.message || "Server Down");
      }
    } catch (error) {
      toast.error("Server Down");
      console.log(error);
    }
  };

  const SubsCourseCancel = async (id) => {
    try {
      const response = await fetch(
        `${baseURL}/api/user/subsription/cancel/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = await response.json();
      if (response.ok && data.status === 200) {
        // getSubsCourseDetail()
        toast.success(data.message);
        navigate("/my-subscription");
      } else {
        toast.error(data?.message || "Server Down");
      }
    } catch (error) {
      toast.error("Server Down");
      console.log(error);
    }
  };

  const getsellerinfo = async (dataToSend) => {
    try {
      SetListLoading({ ...listLoading, cartList: true });

      const response = await axios.post(
        `${baseURL}/api/seller/profile/details`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );

      if (response.status === 200) {
        setSellerInfo(response.data.data);
      }
    } catch (error) {
      toast.error("Server Down");
      // console.log(error);
    } finally {
      SetListLoading({ ...listLoading, cartList: false });
    }
  };

  const ContactMessage = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/contact-us/message`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error("Server Down");
      // console.log(error);
    }
  };

  const getForgetEmail = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/user/forget-password`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        LogOut();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "server error");
    }
  };

  const getNewPassword = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/user/new-password`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        // LogOut()
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "server error");
    }
  };

  const getreviews = async (product_id) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/product/review/list`,
        {
          productId: product_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      // const res = response.data;
      if (response.status === 200 && response.data.status === 200) {
        setReviews(response?.data);
      } else {
        toast.error("Server Down");
        setReviews([]);
      }
    } catch (error) {
      setReviews([]);
      console.error("Error in product review:", error);
    }
  };

  const reviewDelete = async (dataToSend) => {
    try {
      const { product_id, reviewId } = dataToSend;
      const response = await axios.get(
        `${baseURL}/api/product/review/delete/${reviewId}`,

        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      // const res = response.data;
      if (response.status === 200) {
        getreviews(product_id);
      } else {
        toast.error("Server Down");
      }
    } catch (error) {
      console.error("Error in product review:", error);
    }
  };

  const productrating = async (formData) => {
    try {
      const product_id = formData.get("productId");
      const req = await axios.post(`${baseURL}/api/product/review`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: token,
        },
      });
      const res = req.data;
      toast(res.message);
      getreviews(product_id);
    } catch (error) {
      console.error("Error in product review:", error);
    }
  };

  const sendProposal = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/freelancer/proposal`,
        { ...dataToSend },
        {
          headers: {
            token: token,
          },
        }
      );
      const data = response;
      toast.success(data.message || "Success");
      return data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getTeacherPage = async (product_id) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/subscribed/users`,
        { product_id: String(product_id) },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response?.data;
      console.log(data.data);
      setSubsList(data?.data);
      return data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const searchRecord = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/user/product/search/record`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response;
      return data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const applicantList = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/job/applicants/list`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setGetApplicantList(response?.data);
      } else {
        // toast.error("Server Down");
        setGetApplicantList([]);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getpaymentList = async (user_id) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/seller/transaction/list`,
        user_id,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setMainData({ paymentList: response?.data?.data?.docs });
      } else {
        toast.error("Server Down");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const createPayoutRequest = async (formdata) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseURL}/api/payout-request/create`,
        { ...formdata },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        getPayoutRequestList();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getPayoutRequestList = async (formdata) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/seller/payout-request/list`,
        { ...formdata },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setMainData({ payOutList: response?.data?.data?.docs });
      } else {
        toast.error("Server Down");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const connectionList = async (dataToSend) => {
    try {
      SetListLoading({ ...listLoading, connectionLists: true });
      const response = await axios.post(
        `${baseURL}/api/user/connections/list`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setGetConnectionsList(response?.data);
      } else {
        toast.error("Server Down");
        setGetConnectionsList([]);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      SetListLoading({ ...listLoading, connectionLists: false });
    }
  };

  const sendConnection = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/connection-request/send`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response;
      toast.success(response.data.message);
      return data;
    } catch (error) {}
  };

  const receivedList = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/user/pending/requests/list`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setGetreceivedList(response?.data);
      } else {
        // toast.error("Server Down");
        setGetreceivedList([]);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const ConnectionRequest = async (dataToSend) => {
    try {
      const { id } = dataToSend;
      const response = await axios.post(
        `${baseURL}/api/connection-request/approve/reject/${id}`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response;
      receivedList();
      toast.info(response.data.message);
      return data;
    } catch (error) {}
  };

  const mySendList = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/user/send/requests/list`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setmysendreq(response?.data);
      } else {
        // toast.error("Server Down");
        setmysendreq([]);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const ConnectionRequestWithdraw = async (dataToSend) => {
    try {
      const { id } = dataToSend;
      const response = await axios.get(
        `${baseURL}/api/user/connection/request/withdraw/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response;
      mySendList();
      toast.info(response.data.message);
      return data;
    } catch (error) {}
  };

  const ConnectionRequestRemove = async (dataToSend) => {
    try {
      const { id } = dataToSend;
      const response = await axios.get(
        `${baseURL}/api/user/connection/remove/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response;
      connectionList();
      toast.info(response.data.message);
      return data;
    } catch (error) {}
  };

  const getMessageList = async () => {
    try {
      // setLoading(true);
      const response = await axios.post(
        `${baseURL}/api/chat/users/list`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setMainData({ messageList: response?.data?.data?.docs });
      } else {
        // toast.error("Server Down");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      // setLoading(false)
    }
  };

  const createChatList = async (user2_id) => {
    try {
      // setLoading(true);
      //eslint-disable-next-line
      const response = await axios.post(
        `${baseURL}/api/chat/users/create`,
        { user2_id },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getCmsList = async (slug) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseURL}/api/cms/details`,
        { slug },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setMainData({ aboutUs: response?.data?.data });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getPromoCodeList = async (body) => {
    try {
      setPromoCode({ loading: false, list: [] });
      const { data } = await axios.post(
        `${baseURL}/api/promo_code/list`,
        body || {},
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (data.status === 200) {
        setPromoCode({ loading: false, list: data?.data || [] });
      } else {
        setPromoCode({ loading: false, list: [] });
        toast.error(data?.message);
      }
    } catch (error) {
      setPromoCode({ loading: false, list: [] });
      toast.error(error.message);
    }
  };

  const getJobAppliedList = async (body) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/job/applied/list`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        setJobAppliedList({ data: data.data, totalPages: data.pages });
      } else {
        setJobAppliedList({ data: [], totalPages: 1 });
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
    }
  };

  const cartAdd = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/user/product/cart/add`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response;
      toast.success(response.data.message);
      return data;
    } catch (error) {}
  };

  const getSellerJobList = async (body) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/seller/job/list`,
        body || {},
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        setSellerJobList({
          data: data.data,
          totalPages: data.pages,
          total: data?.total,
        });
      } else {
        setSellerJobList({ data: [], totalPages: 1, total: 0 });
        toast.error(data?.message);
      }
    } catch (error) {
      // console.log(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
    }
  };

  const getSellerJobApplicant = async (body) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/seller/job/applications/list`,
        body || {},
        {
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        setApplicantList({ data: data.data, totalPages: data.pages });
      } else {
        setApplicantList({ data: [], totalPages: 1 });
        toast.error(data?.message);
      }
    } catch (error) {
      // console.log(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
    }
  };

  const updateJobApplicant = async (id, body) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/job/application/update/${id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      // console.log(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const getCountryList = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/countries/list`, {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      if (response.status === 200) {
        setMainData({ countryList: response?.data?.data });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateOrderStatus = async (datatoSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/seller/order/status/update`,
        { ...datatoSend },
        {
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      // console.log(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const orderValuePanel = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/api/seller/order/count`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        setSellertotalValue({ data: data, totalPages: data.pages });
      } else {
        setSellertotalValue({ data: {}, totalPages: 1 });
        toast.error(data?.message);
      }
      return data;
    } catch (error) {
      // console.log(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const courseList = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/api/seller/courses/list`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        setCourseOrderList({ data: data, totalPages: data.pages });
      } else {
        setCourseOrderList({ data: {}, totalPages: 1 });
        toast.error(data?.message);
      }
      return data;
    } catch (error) {
      // console.log(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const courseLearnerList = async (body) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/subscribed/users`,
        { ...body },
        {
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        setCourseLearner({ data: data, totalPages: data.pages });
      } else {
        setCourseLearner({ data: {}, totalPages: 1 });
        toast.error(data?.message);
      }
      return data;
    } catch (error) {
      // console.log(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const sellerOrderList = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/seller/orders/list`,
        { ...dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        setSellerordersList(data);
      } else {
        setSellerordersList({ data: [] });
        // toast.error(data?.message);
      }
    } catch (error) {
      // console.log(error);
      // toast.error(
      //   error?.response?.data?.message || error?.message || "Server error"
      // );
      // return null;
    }
  };

  const createFeedPost = async (formdata) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/feed/post/add`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const getFeedList = async (body) => {
    try {
      setFeedData({ ...feedData, isLoading: true });
      const response = await axios.post(
        `${baseURL}/api/feed/post/list`,
        body || {},
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        console.log(data);
        if (body?.page > 1) {
          setFeedList((prevFeedList) => [...prevFeedList, ...data.data.docs]);
        } else {
          setFeedList(data.data.docs);
        }
        setFeedData({
          currentPage: data?.data?.page || 1,
          totalPage: data?.data?.pages,
          isLoading: false,
        });
      } else {
        setFeedData({ ...feedData, isLoading: false });
        toast.error(data?.message);
      }
    } catch (error) {
      // console.log(error);
      setFeedData({ ...feedData, isLoading: false });
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
    }
  };

  const getPostDetail = async (id) => {
    try {
      setPostDetail({ data: {}, isLoading: true });
      const response = await axios.get(
        `${baseURL}/api/feed/post/detail/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        console.log(data);
        setPostDetail({ data: data.data, isLoading: false });
      } else {
        setPostDetail({ data: {}, isLoading: false });
        toast.error(data?.message);
      }
    } catch (error) {
      // console.log(error);
      setPostDetail({ data: {}, isLoading: false });
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
    }
  };

  const postNewComment = async (obj) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/post/comment/new`,
        obj,
        {
          headers: {
            token: token,
          },
        }
      );
      const res = response.data;
      return res;
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const getCommentList = async (body) => {
    try {
      setCommentData({ ...commentData, isLoading: true });
      const response = await axios.post(
        `${baseURL}/api/post/comment/list`,
        body || {},
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        if (body?.page > 1) {
          setCommentList((prevCommentList) => [
            ...prevCommentList,
            ...data.data.docs,
          ]);
        } else {
          setCommentList(data.data.docs);
        }
        setCommentData({
          currentPage: data?.data?.page || 1,
          totalPage: data?.data?.pages,
          isLoading: false,
        });
      } else {
        setCommentData({ ...commentData, isLoading: false });
        toast.error(data?.message);
      }
    } catch (error) {
      // console.log(error);
      setCommentData({ ...commentData, isLoading: false });
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
    }
  };

  const addReaction = async (obj) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/post/reaction/add`,
        obj,
        {
          headers: {
            token: token,
          },
        }
      );
      const res = response.data;
      return res;
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const removeReaction = async (post_id) => {
    try {
      const response = await axios.delete(
        `${baseURL}/api/post/reaction/remove/${post_id}`,
        {
          headers: {
            token: token,
          },
        }
      );
      const res = response.data;
      return res;
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const getReplyList = async (body) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/post/comment/list`,
        body || {},
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        return data.data;
      } else {
        return null;
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const getTrendingPost = async () => {
    try {
      setTrendingPosts({ isLoading: true, data: [] });
      const response = await axios.get(`${baseURL}/api/feed/post/trending`, {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      const data = response.data;
      if (data.status === 200) {
        setTrendingPosts({ isLoading: false, data: data.data });
      } else {
        setTrendingPosts({ isLoading: false, data: [] });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      setTrendingPosts({ isLoading: false, data: [] });
    }
  };

  const recentConnectionList = async () => {
    try {
      setRecentConnections({ isLoading: true, data: [] });
      const { data } = await axios.get(
        `${baseURL}/api/user/connection-list/recent`,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (data.status === 200) {
        setRecentConnections({ isLoading: false, data: data.data });
      } else {
        // toast.error("Server Down");
        setRecentConnections({ isLoading: false, data: [] });
      }
    } catch (error) {
      toast.error(error.message);
      setRecentConnections({ isLoading: false, data: [] });
    }
  };

  const deletePost = async (post_id) => {
    try {
      const response = await axios.delete(
        `${baseURL}/api/post/delete/${post_id}`,
        {
          headers: {
            token: token,
          },
        }
      );
      const res = response.data;
      return res;
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const commentDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${baseURL}/api/comment/delete/${id}`,
        {
          headers: {
            token: token,
          },
        }
      );
      const res = response.data;
      return res;
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  function timeAgo(dateString) {
    const givenDate = new Date(dateString);
    const currentDate = new Date();

    const diffInMilliseconds = currentDate - givenDate;

    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    if (diffInDays > 0) {
      return `${diffInDays}d`;
    } else if (diffInHours > 0) {
      return `${diffInHours}h`;
    } else {
      return `${diffInMinutes}m`;
    }
  }

  const updateFeedPost = async (formdata, id) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/post/update/${id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const getReactionList = async (body) => {
    try {
      setReactionData({ ...reactionData, isLoading: true });
      const response = await axios.post(
        `${baseURL}/api/post/reaction/list`,
        body || {},
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        console.log(data);
        if (body?.page > 1) {
          setReactionList((prevFeedList) => [
            ...prevFeedList,
            ...data.data.docs,
          ]);
        } else {
          setReactionList(data.data.docs);
        }
        setReactionData({
          currentPage: data?.data?.page || 1,
          totalPage: data?.data?.pages,
          isLoading: false,
          totalReactions: data?.data?.total,
        });
      } else {
        setReactionData({ ...reactionData, isLoading: false });
        toast.error(data?.message);
      }
    } catch (error) {
      // console.log(error);
      setReactionData({ ...reactionData, isLoading: false });
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
    }
  };

  const getNotificationList = async (body) => {
    try {
      // setReactionData({...reactionData, isLoading: true})
      const response = await axios.post(
        `${baseURL}/api/user/notifications`,
        body || {},
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        setNotificationList(data.data.docs);
        // setReactionData({currentPage: data?.data?.page || 1, totalPage: data?.data?.pages, isLoading: false, totalReactions: data?.data?.total})
      } else {
        // setReactionData({...reactionData, isLoading: false})
        toast.error(data?.message);
      }
    } catch (error) {
      // setReactionData({...reactionData, isLoading: false})
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
    }
  };

  const getTopCityList = async (dataToSend) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/country/top/cities/list`,
        { country: dataToSend },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        setTopCity(data);
      } else {
        setTopCity([]);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const getCurrencyList = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/api/currencies/list`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        setCurrency(data);
      } else {
        setCurrency([]);
      }
    } catch (error) {
      console.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const commentUpdate = async (id, body) => {
    try {
      const response = await axios.put(
        `${baseURL}/api/comment/update/${id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const data = response.data;
      if (data.status === 200) {
        return data;
      } else {
        toast.error(data.message);
        return null;
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Server error"
      );
      return null;
    }
  };

  const getPremiumJobList = async () => {
    setpremiumJobSeekers({ data: [], loading: true });
    try {
      const { status, data } = await axios.post(
        `${baseURL}/api/premium/job/applicants/list`,
        {}
      );
      if (status === 200) {
        setpremiumJobSeekers({ data: data?.data || [], loading: false });
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.message || "Server error");
      setpremiumJobSeekers({ data: [], loading: false });
      return null;
    }
  };

  const gettopFreelancerList = async () => {
    setTopFreelancer({ data: [], loading: true });
    try {
      const { status, data } = await axios.get(
        `${baseURL}/api/top/freelancers/list`
      );
      if (status === 200) {
        setTopFreelancer({ data: data?.data || [], loading: false });
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.message || "Server error");
      setTopFreelancer({ data: [], loading: false });
      return null;
    }
  };

  const gettopEducatorList = async () => {
    setTopEducator({ data: [], loading: true });
    try {
      const { status, data } = await axios.get(
        `${baseURL}/api/top/educators/list` );
      if (status === 200) {
        setTopEducator({ data: data?.data || [], loading: false });
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.message || "Server error");
      setTopEducator({ data: [], loading: false });
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setMainscreenLoading(true);
      try {
        await getUser();
        await getServiceList();
      } catch (error) {
      } finally {
        setMainscreenLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const contextValues = {
    RegisterUser,
    getPremiumJobList,
    premiumJobSeekers,
    gettopFreelancerList,
    gettopEducatorList,
    topEducator,
    topFreelancer,
    LoginUser,
    isLogIn,
    LogOut,
    userData,
    cmsData,
    getCms,
    checkTokenValidity,
    loading,
    SocialLoginUser,
    serviceList,
    goodsList,
    electronicsList,
    getGoodsProductDetails,
    goodsItems,
    setGoodsItems,
    getElectronicsProductDetails,
    electronicsItems,
    setElectronicsItems,
    getUserplan,
    packagesList,
    ChnagePassword,
    mainscreenLoading,
    updateBank,
    setMainscreenLoading,
    sellerinquiry,
    inquiry,
    getinquiryList,
    baseURL,
    token,
    reviewDelete,
    jobAppliedList,
    getJobAppliedList,
    getSellerJobList,
    sellerJobList,
    applicant_list,
    getSellerJobApplicant,
    updateJobApplicant,
    getWishList,
    getFreelancer,
    freelancerList,
    freelancerDetail,
    setFreelancerDetail,
    getFreelancerDetails,
    fashion,
    wishListAdd,
    wishListRemove,
    wishList,
    faq,
    getFaq,
    skeleton,
    property,
    getPropertyDetail,
    propertyDetail,
    lesson,
    jobList,
    getGoodsProduct,
    totalItem,
    getJobList,
    getLesson,
    getElectronicsProduct,
    getFashionBeauty,
    getProperty,
    setPropertyDetail,
    listLoading,
    jobDetail,
    setJobDetail,
    getJobListDetail,
    lessonDetail,
    setlessonDetail,
    getLessonDetail,
    fashionDetail,
    setFashionDetail,
    getFashionBeautyDetails,
    getSubServiceList,
    subServiceList,
    subServiceLoading,
    AddTitleDes,
    AddRealEstate,
    getAttributeList,
    attributeList,
    productId,
    AddJob,
    AddFashionProduct,
    AddFreelancer,
    AddElectronics,
    AddCourse,
    AddGoodsProduct,
    getMyPost,
    mypost,
    myPostRemove,
    getMyPostListDetail,
    myPostDetail,
    updateMyPost,
    getAttributeFilterList,
    filterAttributeList,
    BidAdd,
    cartListAdd,
    getCartList,
    cartList,
    cartListRemove,
    cartListUpdate,
    getReletedProduct,
    reletedList,
    totalprice,
    orderCheckOut,
    getMyOrderList,
    orderList,
    getAllProductDetails,
    allPDetails,
    getAllUnAuthProductDetails,
    jobApply,
    getMessageDetail,
    messageDetail,
    getPeerUserDetail,
    peerUserData,
    getCallDetail,
    getBannerList,
    bannerList,
    scheduledMeeting,
    getScheduledMeetingsList,
    getPlanCreate,
    check,
    createFreePayment,
    getSubscriptionList,
    subscriptionList,
    getSubsCourseDetail,
    subsCourseDetail,
    SubsCourseCancel,
    ContactMessage,
    getsellerinfo,
    sellerinfo,
    subsId,
    titleDes,
    navigate,
    getForgetEmail,
    getNewPassword,
    productrating,
    getreviews,
    reviews,
    sendProposal,
    sitelocation,
    setSiteLocation,
    getTeacherPage,
    subsList,
    incomingCall,
    setIncomingCall,
    searchRecord,
    applicantList,
    getApplicantList,
    createPayoutRequest,
    getPayoutRequestList,
    mainData,
    getpaymentList,
    updateNewProfile,
    setUserData,
    connectionList,
    getConnectionsList,
    sendConnection,
    getreceivedList,
    receivedList,
    ConnectionRequest,
    mySendList,
    mysendreq,
    ConnectionRequestWithdraw,
    ConnectionRequestRemove,
    getMessageList,
    getCmsList,
    cartListClear,
    createChatList,
    getPromoCodeList,
    promoCode,
    cartAdd,
    getCountryList,
    sellerordersList,
    sellerOrderList,
    updateOrderStatus,
    orderValuePanel,
    sellerTotalValue,
    courseOrderList,
    courseList,
    courseLearnerList,
    courseLearner,
    createFeedPost,
    updateFeedPost,
    getFeedList,
    feedList,
    feedData,
    setFeedData,
    getPostDetail,
    postDetail,
    postNewComment,
    getCommentList,
    commentList,
    commentData,
    removeReaction,
    addReaction,
    getReplyList,
    getTrendingPost,
    trendingPosts,
    recentConnectionList,
    recentConnections,
    commentDelete,
    deletePost,
    timeAgo,
    reactionList,
    getReactionList,
    reactionData,
    getNotificationList,
    notificationList,
    getTopCityList,
    topCity,
    getCurrencyList,
    currency,
    setCurrency,
    setGlobalCurrency,
    globalCurrency,
    setCurrency_symbol,
    currency_symbol,
    commentUpdate,
    setCommentList,
  };

  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};

export default AppContextProvider;
