import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import './FeedPage.css';
import PostCard from './component/post_card';
import CreatePost from './component/create_post_card';
import { useAppContext } from '../../contextApi/AppContext';
import LeftSidebar from './component/left_sidebar';
import { RightSidebar, TrendingPostsSkeleton } from './component/right_sidebar';
import RecentConnections from './component/recent-connections';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PostModal } from './component/post_modal';

const Feed = () => {
  const { getFeedList, feedList, feedData, getTrendingPost, trendingPosts, recentConnectionList, recentConnections, isLogIn } = useAppContext()
  const [initialData, setInitialData] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    getFeedList({ page: 1, limit: 10 })
  }, [])

  useEffect(() => {
    if (trendingPosts.isLoading === false && trendingPosts.data === null) {
      getTrendingPost()
    }
  }, [trendingPosts])

  useEffect(() => {
    if (recentConnections.isLoading === false && recentConnections.data === null && isLogIn) {
      recentConnectionList()
    }
  }, [recentConnections])

  const fetchData = async () => {
    const page = feedData.currentPage + 1
    if (feedData.currentPage >= feedData.totalPage) {
      return
    }
    await getFeedList({ page, limit: 10 })
  }

  const onHide = () => {
    setModalOpen(false)
    setInitialData(null)
  }

  const onOpen = (data) => {
    setModalOpen(true)
    setInitialData(data)
  }

  return (
    <Container fluid className="feed-container">
      <PostModal onHide={onHide} showModal={modalOpen} initialData={initialData} isEdit={true} />
      <Row>
        <Col md={3} className="sidebar">
          {/* Sidebar content */}
          <LeftSidebar />
        </Col>
        <Col md={6}>
          <CreatePost />
          <InfiniteScroll
            dataLength={feedList.length} //This is important field to render the next data
            next={fetchData}
            hasMore={feedData.currentPage < feedData.totalPage}
            loader={(
              <div className='d-grid' style={{ placeItems: 'center' }}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {!feedData.isLoading && feedList?.map((post, i) => (
              <PostCard post={post} key={i} onOpen={onOpen} />
            ))}
          </InfiniteScroll>
          {feedData.isLoading && feedList.length === 0 && (
            <div className='d-grid' style={{ placeItems: 'center' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        </Col>
        <Col md={3} className="sidebar">
          {/* Sidebar content */}
          {recentConnections.isLoading ? (
            <TrendingPostsSkeleton />
          ) : (
            <RecentConnections />
          )}
          {trendingPosts.isLoading ? (
            <TrendingPostsSkeleton />
          ) : (
            <RightSidebar />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Feed;