import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import noPostar from "../../assets/no-poster.png";
import { FaStar } from "react-icons/fa";
import { FaCartArrowDown } from "react-icons/fa";
import Timer from "../comon/Timer";
import { toast } from "react-toastify";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";

const GoodsOfAllKind = ({ item, index }) => {
  const {
    goodsList,
    sitelocation,
    getGoodsProduct,
    wishListRemove,
    wishListAdd,
    userData,
    cartAdd,
    globalCurrency,
    currency_symbol,
    isLogIn,
  } = useAppContext();
  const [colorMap, setColorMap] = useState([]);
  const location1 = useLocation();
  const navigate = useNavigate();

  const redirect = (path) => {
    if (isLogIn) {
      navigate(path);
    } else {
      toast("please Login first");
      setTimeout(() => {
        navigate(`/login?redirect=${location1.pathname}`);
      }, 1000);
    }
  };

  useEffect(() => {
    const dataToSend = {
      addressName: sitelocation,
      currency: globalCurrency,
    };
    getGoodsProduct("", "", "", dataToSend);
    // eslint-disable-next-line
  }, [sitelocation, globalCurrency, currency_symbol]);

  const addWhishlist = (id) => {
    if (colorMap[id]) {
      wishListRemove(id);
      const filtered = { ...colorMap };
      filtered[id] = false;
      setColorMap(filtered);
    } else {
      wishListAdd(id);
      setColorMap((prevColorMap) => ({
        ...prevColorMap,
        [id]: !prevColorMap[id],
      }));
    }
  };

  const addToCart = (item) => {
    if (!item.price) {
      toast.warning("Price is not defined");
      return;
    }

    const dataToSend = {
      product_id: item.product_id,
      quantity: 1,
      price: item.price,
    };
    cartAdd(dataToSend);
  };

  return (
    <>
      <section style={{ background: "#f7feff" }}>
        <article className="container pt-5 pb-2">
          <div className="d-flex justify-content-between align-items-center titelBx">
            <h4 className="mb-0">{item.title}</h4>
            <Link
              className="btn btn-primary rounded-pill px-4 text-white"
              to={`/${item.slug}?category_id=${item._id}`}
              onMouseEnter={(e) =>
                (e.target.style.color = "var(--theme-color)")
              }
              onMouseLeave={(e) => (e.target.style.color = "black")}
              style={{
                background: "linear-gradient(45deg, #007bff, #6610f2)",
                border: "none",
                fontSize: "0.95rem",
                fontWeight: "600",
              }}
            >
              View All
            </Link>
          </div>

          <aside className="row pt-2 ">
            <Swiper
              spaceBetween={20}
              navigation={true}
              mousewheel={false}
              keyboard={true}
              loop={true}
              pagination={false}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                360: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
              }}
              modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
              className="mySwiper homeCategory"
            >
              {Array.isArray(goodsList) &&
                goodsList?.map((item) => {
                  const { attribute_values } = item;
                  const brand = attribute_values?.find(
                    (attribute) => attribute.attribute === "Brand"
                  );

                  const bidEnded =
                    item.bid_now && new Date(item.bid_end_date) < new Date();

                  return (
                    <SwiperSlide key={item._id}>
                      <div>
                        <div
                          className={`card card-custom shadow-sm border-0 rounded-4 position-relative `}
                        >
                          <div
                            className="position-relative"
                            style={{ height: "200px" }}
                          >
                            <Link to={`/product-detail/${item._id}`}>
                              <img
                                src={item?.image[0] || noPostar}
                                className={`card-img-top rounded-top-4 ${
                                  bidEnded ? "opacity-25" : ""
                                } `}
                                alt=""
                                style={{ height: "100%", objectFit: "cover" }}
                              />
                            </Link>
                            {item?.bid_now && (
                              <div
                                className={`timer_badge ${
                                  bidEnded ? "d-none" : ""
                                }`}
                              >
                                <Timer
                                  endDate={item?.bid_end_date}
                                  startDate={item?.bid_start_date}
                                />
                              </div>
                            )}
                            {item.userId === userData._id ? (
                              ""
                            ) : (
                              <div
                                className={`position-absolute top-0 end-0 m-2 ${
                                  bidEnded ? "d-none" : ""
                                } `}
                                onClick={
                                  !isLogIn
                                    ? redirect
                                    : () => addWhishlist(item.product_id)
                                }
                              >
                                <span
                                  className="p-2 rounded"
                                  style={{
                                    background: "#ffffffd1",
                                    color: "#000",
                                  }}
                                >
                                  <i
                                    className={
                                      colorMap[item.product_id]
                                        ? "fa fa-heart text-danger"
                                        : "fa fa-heart-o"
                                    }
                                  />
                                </span>
                              </div>
                            )}
                            {item.ratings > 0 ? (
                              <div
                                className="position-absolute bottom-0 start-0 m-2 text-light px-2 rounded"
                                style={{
                                  backgroundColor: "#fffbdf",
                                }}
                              >
                                <strong className="text-black">
                                  {item.ratings}
                                </strong>
                                <strong>
                                  <FaStar
                                    color="#fdc701"
                                    style={{ marginTop: "-4px" }}
                                  />
                                </strong>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="card-body d-flex flex-column">
                            <span className="text-truncate"> {brand?.value} </span>
                            <h6
                              className={`card-title text-truncate ${
                                bidEnded ? "opacity-25" : ""
                              } `}
                            >
                              {item.title}
                            </h6>
                            <p
                              className={`card-text fw-bold text-primary m-0 ${
                                bidEnded ? "opacity-25" : ""
                              } `}
                            >
                              Price: {currency_symbol ? currency_symbol : "$"}{" "}
                              {item.converted_currency_price}
                            </p>

                            <p
                              className={`card-text text-muted small m-0 mt-1 ${
                                bidEnded ? "opacity-25" : ""
                              }`}
                            >
                              <strong>Posted By:</strong> {item.seller_name}
                            </p>
                            {bidEnded && (
                              <span
                                className={`badge bg-danger ${
                                  bidEnded ? "opacity-100" : ""
                                }`}
                              >
                                Bid Ended
                              </span>
                            )}
                            <div
                              className={`d-flex mt-2 ${
                                bidEnded ? "opacity-100" : ""
                              }`}
                            >
                              <button
                                className="btn btn-primary me-2 text-white"
                                style={{ width: "75%" }}
                                disabled={bidEnded}
                                onClick={
                                  !isLogIn
                                    ? redirect
                                    : () => {
                                        addToCart(item);
                                      }
                                }
                              >
                                Buy Now
                              </button>
                              <button
                                className="btn btn-success"
                                disabled={bidEnded}
                                onClick={
                                  !isLogIn
                                    ? redirect
                                    : () => {
                                        addToCart(item);
                                      }
                                }
                              >
                                <FaCartArrowDown />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            {/* item start */}
          </aside>
        </article>
      </section>
    </>
  );
};

export default GoodsOfAllKind;
