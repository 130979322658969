import React, { useEffect } from 'react';
import { Card, Image } from 'react-bootstrap';
import { useAppContext } from '../../../contextApi/AppContext';
import { Link } from 'react-router-dom';
import { MdArrowOutward } from 'react-icons/md';

const LeftSidebar = () => {
    const { userData, getConnectionsList, getreceivedList, connectionList, receivedList, isLogIn } = useAppContext();

    useEffect(() => {
        if ((getConnectionsList.length === 0) && isLogIn) {
            connectionList();
        }
        if ((getreceivedList.length === 0) && isLogIn) {
            receivedList();
        }
        // eslint-disable-next-line
    }, [getConnectionsList, getreceivedList]);

    return (
        <Card className="mb-3 position-sticky" style={{ top: 128 }}>
            <Card.Body className="text-center">
                <Image
                    src={isLogIn ? userData?.image : 'https://via.placeholder.com/80'}
                    roundedCircle
                    style={{ width: 80, height: 80, objectFit: 'cover' }}
                    className="mb-2"
                />
                <Card.Title>{isLogIn ? userData?.name : 'Guest User'}</Card.Title>
                <Card.Text>
                    {isLogIn
                        ? userData?.bio || 'User prefers not to disclose anything.'
                        : 'Log in to view your profile and connections.'}
                </Card.Text>
                {isLogIn && (
                    <div className="">
                        <Link
                            to="/user-connectionlist"
                            style={{ textDecoration: 'none' }}
                        >
                            <strong>
                                {getConnectionsList?.total_connections || 0} Connections <MdArrowOutward />
                            </strong>
                        </Link>
                        <Link
                            to="/user-invitionlist"
                            style={{ textDecoration: 'none' }}
                        >
                            <strong>
                                {getreceivedList?.total_pending_requests || 0} Invitations <MdArrowOutward />
                            </strong>
                        </Link>
                    </div>
                )}
            </Card.Body>
            <Card.Footer className="text-center">
                <Card.Link as={Link} to='/packages?show_profile=true'>Upgrade to premium</Card.Link>
            </Card.Footer>
        </Card>
    );
};

export default LeftSidebar;
