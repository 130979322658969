import React, { useState } from "react";
import { Card, Image } from "react-bootstrap";
import { useAppContext } from "../../../contextApi/AppContext";
import { FaPlusCircle } from "react-icons/fa";
import { PostModal } from "./post_modal";
import { useNavigate } from "react-router-dom";

const CreatePost = () => {
  const { userData, isLogIn } = useAppContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  const onHide = () => {
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    if (!isLogIn) {
      navigate("/login"); // Navigate to the login page if not logged in
    } else {
      setModalOpen(true);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleOpenModal();
  };

  return (
    <Card className="create-post bg-theme">
      <Card.Body className="px-2">
        <div className="d-flex align-items-center gap-3">
          <Image src={userData?.image || 'https://via.placeholder.com/80'} roundedCircle className="profile-img" />
          <form
            style={{ width: "100%" }}
            onSubmit={handleFormSubmit}
          >
            <input
              placeholder="Write something.."
              className="post-input form-control"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </form>
          <FaPlusCircle
            style={{ fontSize: 32, cursor: "pointer" }}
            onClick={handleOpenModal}
          />
        </div>
        <PostModal
          onHide={onHide}
          showModal={modalOpen}
          initialData={{ title: content }}
          clearContent={setContent}
        />
      </Card.Body>
    </Card>
  );
};

export default CreatePost;
