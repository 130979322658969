import React, { useEffect } from "react";
import CustomSlick from "../components/sliders/Slider";
import GoodsOfAllKind from "../components/home/GoodsOfAllKind";
import Fashion from "../components/home/Fashion";
import Lesson from "../components/home/Lesson";
import Jobs from "../components/home/Jobs";
import TopCity from "../components/home/TopCity";
import { useAppContext } from "../contextApi/AppContext";
import FreeLancer from "../components/home/FreeLancer";
import RealEstate from "../components/home/RealEstate";
import ElectronicsBanner from "../components/home/ElectronicsBanner";
import CategoryBanner from "./home/CategoryBanner";
import BannerAnimation from "./home/BannerAnimation";
import DzitalChad from "./home/DzitalChad";
import VcardDzital from "./home/VcardDzital";
import SwiperComponent from "./test";
import image1 from "../assets/assets/images/model-1.jpg";
import image2 from "../assets/assets/images/model-2.jpg";
import image3 from "../assets/assets/images/model-3.jpg";
import image4 from "../assets/assets/images/model-4.jpg";

const Home = () => {
  const {
    serviceList,
    getPremiumJobList,
    premiumJobSeekers,
    gettopFreelancerList,
    topFreelancer,
    gettopEducatorList,
    topEducator,
  } = useAppContext();

  console.log(premiumJobSeekers?.data);
  useEffect(() => {
    getPremiumJobList();
    gettopFreelancerList();
    gettopEducatorList();
  }, []);

  return (
    <>
      <CustomSlick />
      <SwiperComponent title={"Top Educators"} data={topEducator.data} />
      <CategoryBanner />
      <div className="bgtheme">
        {Array.isArray(serviceList) &&
          serviceList.map((item, index) => {
            const SectionComponent = getSectionComponent(item?.slug);
            return (
              <div key={index}>
                <SectionComponent item={item} index={index} />
                {index === 0 && (
                  <SwiperComponent
                    title={"Premium Job Seekers"}
                    data={premiumJobSeekers?.data}
                  />
                )}
                {index === 1 && (
                  <SwiperComponent
                    title={"Top Freelancers"}
                    data={topFreelancer?.data}
                  />
                )}
                {index === 2 && <DzitalChad />}
                {index === 4 && <VcardDzital />}
              </div>
            );
          })}
        <div>
          <TopCity />
        </div>
      </div>
      <BannerAnimation />
    </>
  );
};

const getSectionComponent = (slug) => {
  switch (slug) {
    case "goods_of_all_kinds":
      return GoodsOfAllKind;
    case "fashion_beauty":
      return Fashion;
    case "lesson_courses":
      return Lesson;
    case "jobs":
      return Jobs;
    case "freelancer":
      return FreeLancer;
    case "real_estate":
      return RealEstate;
    case "electronics":
      return ElectronicsBanner;
    default:
      return () => null;
  }
};

export default Home;
